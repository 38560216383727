import { parse } from 'date-fns'

import BaseService from '@/services/BaseService'
import { WbEnvironments } from '@interco/inter-webview-bridge'

import Colors from './Colors'
import FontFamily from './FontFamily'
import GoogleTags from './GoogleTags'

export const SALDO_MINIMO = 100
export const DEBOUNCE_TIME_SIMULACAO = 1000

export { Colors, FontFamily, GoogleTags }

export const getMensagemErro = (e: ErrorResponseApi) =>
  e?.data?.erro?.mensagem ||
  e?.response?.data?.erro?.mensagem ||
  e?.message?.erro?.mensagem ||
  e?.message

export const parseDate = (date: string, pattern = 'dd/MM/yyyy') => parse(date, pattern, new Date())

export const redirectFGTS = () => {
  if (BaseService.getEnvironment() === WbEnvironments.BROWSER) {
    window.open(
      'https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador&hl=pt_BR&gl=US',
    )
  } else {
    BaseService.openDeeplink(
      BaseService.getEnvironment() === WbEnvironments.ANDROID
        ? 'https://play.google.com/store/apps/details?id=br.gov.caixa.fgts.trabalhador&hl=pt_BR&gl=US'
        : 'https://apps.apple.com/br/app/fgts/id1038441027',
    )
  }
}

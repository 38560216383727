import React from 'react'

import { Loading } from '@interco/inter-ui/components/Loading'
import Page from '@/components/templates/Page'

import { LoadingWrapper } from './style'

const LoadingPage = () => (
  <Page>
    <LoadingWrapper>
      <Loading height={30} width={30} />
    </LoadingWrapper>
  </Page>
)

export default LoadingPage

import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IWbAppInfo } from '@interco/inter-webview-bridge'

export interface InfoState {
  data?: IWbAppInfo
}

const initialState: InfoState = {}

const infoSlice = createSlice({
  name: 'infos',
  initialState,
  reducers: {
    setInfos: (state, action: PayloadAction<IWbAppInfo | undefined>) => {
      state.data = action.payload
    },
  },
})

export default infoSlice.reducer

export const { setInfos } = infoSlice.actions

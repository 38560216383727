import { AcompanhamentoProposta } from '@/models/api/proposta/AcompanhamentoProposta'
import PropostaResponse from '@/models/api/proposta/PropostaResponse'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class PropostaService {
  static getPropostas = (
    cpf?: string,
    page = 0,
    size = 10,
  ): Promise<TResponseType<PropostaResponse[]>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-cpf': cpf || process.env.REACT_APP_CPF || '',
      },
      pathname: `${process.env.REACT_APP_PROPOSTAS_FGTS}?page=${page}&size=${size}`,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })

  static getAcompanharProposta = (
    cpf?: string,
    conta?: string,
  ): Promise<TResponseType<AcompanhamentoProposta>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-cpf': cpf || process.env.REACT_APP_CPF || '',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
      },
      pathname: `${process.env.REACT_APP_ACOMPANHAR_PROPOSTA_FGTS}`,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })
}

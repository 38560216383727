import React from 'react'

import { Navigate } from 'react-router-dom'

import { useAppSelector } from '@/store/hooks'

import routes from './types'

interface Props {
  children: React.ReactElement
}
const PrivateRoute = ({ children }: Props) => {
  const { autorizadoState } = useAppSelector(({ simulacao }) => simulacao)

  if (!autorizadoState?.data?.uuidSimulacao) {
    return <Navigate to={`${routes.HOME}?fluxo=ALTERNATIVO`} replace />
  }

  return children
}

export default PrivateRoute

import { IWbISafeResponse, WbEnvironments } from '@interco/inter-webview-bridge'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BaseService from '@/services/BaseService'
import ContratacaoService from '@/services/ContratacaoService'
import { CcbResponse } from '@/models/api/CCBResponse'
import { HttpStatus } from '@/services/BaseService/types'

import { getMensagemErro } from '../simulacaoSlice'

interface EnvioContrato {
  mensagem: {
    titulo: string
    descricao: string
  }
}
export interface ContratacaoState {
  loading: boolean
  error: boolean
  isafe?: IWbISafeResponse
  envioContrato?: EnvioContrato
  uuidProposta: string
  ccb?: CcbResponse
}

const initialState: ContratacaoState = {
  loading: false,
  error: false,
  uuidProposta: '',
}

export const enviarContrato = createAsyncThunk(
  'contracao/enviar/contrato',
  async (payload: { callback: () => void; uuidProposta: string }, { rejectWithValue }) => {
    try {
      await ContratacaoService.posEnviarContrato({
        enviaSalesForce: false,
        idTipoDocumento: 'TERMO_ACEITE',
        numeroProposta: payload.uuidProposta,
      })

      payload.callback()
      return undefined
    } catch (e) {
      payload.callback()
      return rejectWithValue(e)
    }
  },
)

export const isafeThunk = createAsyncThunk(
  'contracao/isafe',
  async (
    payload: {
      valor: number
      success: () => void
      error: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      let response

      if (BaseService.getEnvironment() !== WbEnvironments.BROWSER) {
        response = await BaseService.isafeFGTS(payload.valor)
        const user = await BaseService.getUserInfo()

        if (!response) {
          payload.error()
          return response
        }

        await ContratacaoService.postAutorizacaoIsafe(response, user?.account)
      }

      payload.success()

      return response
    } catch (e) {
      payload.error()

      return rejectWithValue(e)
    }
  },
)

export const requestRiskThunk = createAsyncThunk(
  'contracao/risk',
  async (
    payload: {
      success: () => void
      error?: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const user = await BaseService.getUserInfo()

      const response = await ContratacaoService.getRisk(user?.account)

      if (response.status === HttpStatus.SUCCESS) {
        payload.success()
      } else if (payload.error) payload.error()

      return response
    } catch (e) {
      if (payload.error) payload.error()
      return rejectWithValue(e)
    }
  },
)

export const contratarThunk = createAsyncThunk(
  'contracao/contratar',
  async (
    payload: {
      sucess: () => void
      erro: (msg?: string) => void
      uuidSimulacao: string
      dataHora: string
      qtPeriodos: number
      seguroSolicitado: boolean
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await ContratacaoService.postContratar(
        payload.uuidSimulacao,
        payload.dataHora,
        payload.qtPeriodos,
        payload.seguroSolicitado,
      )

      payload.sucess()

      return response
    } catch (e) {
      const messageErro = getMensagemErro(e)
      payload.erro(messageErro)
      return rejectWithValue(e)
    }
  },
)

export const getDadosCCB = createAsyncThunk(
  'contracao/ccb',
  async (payload: { uuidSimulacao: string; qtPeriodos: number }, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()

      const { data } = await ContratacaoService.getDadosCCB(
        payload.uuidSimulacao,
        payload.qtPeriodos,
        user?.account,
      )

      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

const contratacaoSlice = createSlice({
  name: 'contratacao',
  initialState,
  reducers: {
    setUuidProposta: (state, action: PayloadAction<string>) => {
      state.uuidProposta = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(contratarThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.uuidProposta = action.payload.data.uuidProposta
      })
      .addCase(contratarThunk.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(contratarThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })

      .addCase(isafeThunk.fulfilled, (state, action) => {
        state.error = false

        state.isafe = action.payload as IWbISafeResponse
      })
      .addCase(isafeThunk.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(isafeThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })

      .addCase(requestRiskThunk.fulfilled, (state) => {
        state.error = false
        state.loading = false
      })
      .addCase(requestRiskThunk.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(requestRiskThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(enviarContrato.fulfilled, (state) => {
        state.error = false
        state.loading = false
        state.envioContrato = {
          mensagem: {
            descricao: 'Comprovante enviado com sucesso !',
            titulo: 'Comprovante enviado por por e-mail.',
          },
        }
      })
      .addCase(enviarContrato.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(enviarContrato.rejected, (state) => {
        state.loading = false
        state.error = true
        state.envioContrato = {
          mensagem: {
            descricao: 'Erro ao tentar enviar o comprovante por email',
            titulo: 'Ops, aconteceu um erro ao tentar enviar o comprovante por e-email ! ',
          },
        }
      })

    builder
      .addCase(getDadosCCB.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.ccb = action.payload
      })
      .addCase(getDadosCCB.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getDadosCCB.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export const { setUuidProposta } = contratacaoSlice.actions
export default contratacaoSlice.reducer

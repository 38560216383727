import { IWbUserInfo } from '@interco/inter-webview-bridge'
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface UsuarioState {
  loading: boolean
  error: boolean
  data?: IWbUserInfo
}

const initialState: UsuarioState = {
  loading: false,
  error: false,
}

const usuarioSlice = createSlice({
  name: 'usuario',
  initialState,
  reducers: {
    setUsuario: (state, action: PayloadAction<IWbUserInfo | undefined>) => {
      state.data = action.payload
    },
  },
})

export const { setUsuario } = usuarioSlice.actions
export default usuarioSlice.reducer

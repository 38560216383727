import thunkMiddleware from 'redux-thunk'

import { configureStore } from '@reduxjs/toolkit'

import { simulacaoReducer } from './simulacaoSlice'
import { propostaReducer } from './propostaSlice'
import contratacaoSlice from './contratacaoSlice'
import contratoReducer from './contratoSlice'
import usuarioSlice from './usuarioSlice'
import segurosSliceReducer from './segurosSlice'
import { debitoAutomaticoSliceReducer } from './debitoAutomaticoSlice'
import infosSliceReducer from './infosSlice'

const store = configureStore({
  reducer: {
    simulacao: simulacaoReducer,
    propostas: propostaReducer,
    contratacao: contratacaoSlice,
    contratos: contratoReducer,
    usuario: usuarioSlice,
    seguros: segurosSliceReducer,
    debitoAutomatico: debitoAutomaticoSliceReducer,
    info: infosSliceReducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(thunkMiddleware),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store

import { GetResponseSeguros } from '@/store/segurosSlice'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class SeguroService {
  static getDadosSeguro = (timeout = 1000): Promise<TResponseType<GetResponseSeguros>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      pathname: `${process.env.REACT_APP_GET_WIDGET_SEGUROS}`,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST_SEGUROS,
      timeout,
    })
}

import ContratoResponse from '@/models/api/backoffice/ContratoResponse'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class ContratoService {
  static getContratoFgts = (cpfCnpj = ''): Promise<TResponseType<ContratoResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        cpfCnpj: cpfCnpj || process.env.REACT_APP_CPF || '',
      },
      pathname: `${process.env.REACT_APP_GET_BACK_OFFICE_CONTRATOS}`,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })
}

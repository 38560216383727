enum Colors {
  WHITE = '#FFFFFF',
  BLACK = '#000000',
  GRAY100 = '#F5F6FA',
  GRAY200 = '#DEDFE4',
  GRAY300 = '#B6B7BB',
  GRAY400 = '#6A6C72',
  GRAY500 = '#161616',
  GRAY700 = '#6A6E81',
  GRAY800 = '#4B4E5C',
  ORANGE100 = '#FFF2E7',
  ORANGE200 = '#FFDAB7',
  ORANGE300 = '#FFB46E',
  ORANGE400 = '#FF9D42',
  ORANGE500 = '#FF7A00',
  SUCCESS100 = '#E9F8F0',
  SUCCESS500 = '#00AA4F',
  ALERT100 = '#FFF7D9',
  ALERT500 = '#FFBA00',
  ERROR100 = '#FEF0ED',
  ERROR500 = '#FF6227',
  YELLOW = '#FFC700',
  RED = '#F56A50',
  RASPBERRY100 = '#E1F5FE',
  RASPBERRY500 = '#0277BD',
}

export default Colors

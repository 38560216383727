import axios from 'axios'

import PayloadPostSimulacao from '@/models/api/PayloadPostSimulacao'
import Utm from '@/models/Utm'
import { HttpStatus } from '@/services/BaseService/types'
import SimulacaoService from '@/services/SimulacaoService'
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import routes from '@/routes/types'
import ResponseSimulacao from '@/models/api/ResponseSimulacao'
import ResponseSimulacaoAutorizadaPost from '@/models/api/ResponseSimulacaoAutorizada'
import PayloadGetSimulacao from '@/models/api/PayloadGetSimulacao'
import validarErro from '@/utils/validarErro'
import PayloadPutDadosOriginacao from '@/models/api/PayloadPutDadosOriginacao'
import BaseService from '@/services/BaseService'
import { WbEnvironments } from '@interco/inter-webview-bridge'
import PropostaService from '@/services/PropostaService'

export interface ErroSimulacaoWeb {
  erro: Erro
  status: number
}

export interface ErroSimulacaoMobile {
  action: string
  message: string
  httpStatus: number
  response: string
}

export interface Response {
  erro: Erro
  status: number
}

export interface Erro {
  mensagem: string
  mensagemDetalhe: string
}

export interface SimulacaoState {
  maxCashback: number
  loading: boolean
  error: boolean
  utm: Utm
  data?: ResponseSimulacao
  naoAutorizadoState: NaoAutorizadoState
  autorizadoState: AutorizadoState
  isCashBack: boolean
  fluxoAlternativo: boolean
  callBack?: string
}

export interface NaoAutorizadoState {
  saldo: number
  qtdAnos: number
  maxParcela: number
}

export interface AutorizadoState {
  qtdAnos: number
  data?: ResponseSimulacaoAutorizadaPost
  qtdMaxNumParcela: number
}

const initialState: SimulacaoState = {
  maxCashback: 200.0, // TODO: buscar valor do cashback
  loading: false,

  error: false,
  utm: {
    campaing: '',
    content: '',
    medium: '',
    source: '',
  },
  naoAutorizadoState: {
    qtdAnos: 15,
    maxParcela: 15,
    saldo: 0,
  },
  autorizadoState: {
    qtdAnos: 15,
    qtdMaxNumParcela: 15,
  },
  isCashBack: false,
  fluxoAlternativo: false,
}

const callBackSimulacao = (
  sucess: (rotas: string, code?: string, status?: number, parcela?: number) => void,
  status: number,
  isAutorizado: boolean,
  nuParcela?: number,
  tipoSimulacao = 'SINCRONA',
) => {
  if (isAutorizado && status === HttpStatus.CREATED && tipoSimulacao === 'ASSINCRONA') {
    sucess(routes.ERROR, 'SIMULACAO_ASSINCRONA')
  } else if (
    (isAutorizado && status === HttpStatus.CREATED && tipoSimulacao === 'SINCRONA') ||
    status === HttpStatus.NO_CONTENT
  ) {
    sucess(routes.ESPERA, undefined, status)
  } else {
    sucess(
      isAutorizado ? routes.AUTORIZADO_RESULTADO : routes.NAO_AUTORIZADO_RESULTADO,
      undefined,
      status,
      nuParcela,
    )
  }
}

export const postSimulacaoThunk = createAsyncThunk(
  'simulacao/postSimulacao',
  async (payload: PayloadPostSimulacao, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()

      const { data, status } = await SimulacaoService.postSimulacao(payload, user?.account)

      if (payload.sucess) {
        callBackSimulacao(
          payload.sucess,
          status,
          payload.autorizaConsultaCaixa,
          data?.nuParcelas || 15,
          data?.tipoSimulacao,
        )
      }

      return data
    } catch (e) {
      if (payload.error) {
        const messageErro = getMensagemErro(e)
        if (messageErro === 'PENDENTE_CONSULTA_SALDO') {
          payload.error(routes.ESPERA)
        } else if (messageErro === 'PROCESSO_ANDAMENTO') {
          const user = await BaseService.getUserInfo()
          const { data } = await PropostaService.getAcompanharProposta(user?.cpf, user?.account)
          payload?.error(`${routes.PROPOSTA}?id=${data.uuidSimulacao}`)
        } else if (messageErro === 'NAO_AUTORIZADO_RECORRENCIA') {
          payload?.error(routes.ERROR, 'NAO_AUTORIZADO')
        } else {
          payload?.error(routes.ERROR, validarErro(messageErro))
        }
      }

      return rejectWithValue(e)
    }
  },
)
export const getSimulacaoThunk = createAsyncThunk(
  'simulacao/getSimulacao',
  async (payload: PayloadGetSimulacao, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()

      const { data, status } = await SimulacaoService.getSimulacao(
        payload.uuid,
        user?.cpf,
        user?.account,
      )

      if (payload.sucess) {
        callBackSimulacao(
          payload.sucess,
          status,
          payload.autorizaConsultaCaixa,
          data?.nuParcelas || 15,
        )
      }

      return data
    } catch (e) {
      if (payload.error) {
        const messageErro = getMensagemErro(e)
        if (messageErro === 'PENDENTE_CONSULTA_SALDO') {
          payload.error(routes.ESPERA)
        } else if (
          messageErro === 'NAO_AUTORIZADO_RECORRENCIA' &&
          payload?.fromPage &&
          payload?.fromPage === routes.HOME
        ) {
          payload?.error(routes.ERROR, 'NAO_AUTORIZADO')
        } else {
          payload?.error(routes.ERROR, validarErro(messageErro))
        }
      }

      return rejectWithValue(e)
    }
  },
)

export const putSimulacaoDadosOriginacaoThunk = createAsyncThunk(
  'simulacao/putSimulacaoDadosOriginacaoo',
  async (payload: PayloadPutDadosOriginacao, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()

      const { data } = await SimulacaoService.putSimulacaoDadosOriginacao(
        payload.uuid,
        payload.qtdPeriodos,
        user?.account,
        payload.autorizaConsultaCaixa,
        payload.saldoFgts || undefined,
      )

      if (payload.sucess) {
        if (data.limiteFlexivel) {
          payload.sucess(routes.LIMITE_FLEXIVEL, data.nuParcelas)
        } else {
          payload.sucess(routes.AUTORIZADO_RESULTADO, data.nuParcelas)
        }
      }
      return {
        data,
        isAutorizado: payload.autorizaConsultaCaixa,
      }
    } catch (e) {
      if (payload.error) {
        payload.error(routes.ERROR, validarErro(getMensagemErro(e)))
      }

      return rejectWithValue(e)
    }
  },
)

export const getCashBack = createAsyncThunk(
  'simulacao/cashback',
  async (payload: { produto: string }, { rejectWithValue }) => {
    try {
      const { data } = await SimulacaoService.getCashBack(payload.produto)

      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const getAutorizacoes = createAsyncThunk(
  'simulacao/autorizacoesCEF',
  async (
    payload: {
      callback: (isExisteCPF: boolean, autorizacao: boolean | undefined) => void
      erro: () => void
    },
    { rejectWithValue },
  ) => {
    try {
      const user = await BaseService.getUserInfo()

      const cpf = user?.cpf || process.env.REACT_APP_AUTORIZACOES_CPF || ''

      const response = await SimulacaoService.getAutorizacoesCEF(cpf)

      const data = response?.data?.autorizacoes

      payload.callback(
        !!data.length,
        data.some((autorizacao) => autorizacao.autorizado),
      )

      return data
    } catch (e) {
      payload.erro()

      return rejectWithValue(e)
    }
  },
)

export const getMensagemErro = (e: unknown) => {
  try {
    if (BaseService.getEnvironment() === WbEnvironments.BROWSER && axios.isAxiosError(e)) {
      return (e?.response?.data as ErroSimulacaoWeb).erro.mensagem
    }

    const erro = (JSON.parse(e as string) as ErroSimulacaoMobile).response
    return (JSON.parse(erro as string) as Response).erro.mensagem
  } catch (ex) {
    return 'SERVICO_TEMPORARIAMENTE_INDISPONIVEL'
  }
}

const simulacaoSlice = createSlice({
  name: 'simulacao',
  initialState,
  reducers: {
    setMaxCashback: (state, action: PayloadAction<number>) => {
      state.maxCashback = action.payload
    },
    setUtm: (state, action: PayloadAction<Utm>) => {
      state.utm = action.payload
    },
    setSaldo: (state, action: PayloadAction<number>) => {
      state.naoAutorizadoState.saldo = action.payload
    },
    setQtdAnosNaoAutorizado: (state, action: PayloadAction<number>) => {
      state.naoAutorizadoState.qtdAnos = action.payload
    },
    setQtdAnosAutorizado: (state, action: PayloadAction<number>) => {
      state.autorizadoState.qtdAnos = action.payload
    },
    setMaxParcelaAutorizado: (state, action: PayloadAction<number>) => {
      state.autorizadoState.qtdMaxNumParcela = action.payload
    },
    setMaxParcelaNaoAutorizado: (state, action: PayloadAction<number>) => {
      state.naoAutorizadoState.maxParcela = action.payload
    },
    resetAutorizado: (state) => {
      state.autorizadoState = initialState.autorizadoState
    },
    setFluxoAlternativo: (state, action: PayloadAction<boolean>) => {
      state.fluxoAlternativo = action.payload
    },
    setCallback: (state, action: PayloadAction<string | undefined>) => {
      state.callBack = action.payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postSimulacaoThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        if (isSimulacaoAutorizada(action.payload)) {
          state.autorizadoState.data = action.payload as ResponseSimulacaoAutorizadaPost
        } else {
          state.data = action.payload as ResponseSimulacao
        }
      })
      .addCase(postSimulacaoThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(postSimulacaoThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })
    builder
      .addCase(getSimulacaoThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.data = action.payload as ResponseSimulacao
      })
      .addCase(getSimulacaoThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(getSimulacaoThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })
    builder
      .addCase(putSimulacaoDadosOriginacaoThunk.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.data = action.payload.data as ResponseSimulacao
        if (action.payload.isAutorizado) {
          const data: ResponseSimulacaoAutorizadaPost = {
            dataOperacao: action.payload.data.dataOperacao,
            uuidSimulacao: action.payload.data.uuidSimulacao,
            nuParcelas: action.payload.data.nuParcelas,
            uuidProposta: action.payload.data.uuidProposta || '',
          }
          state.autorizadoState.data = data
        }
      })
      .addCase(putSimulacaoDadosOriginacaoThunk.pending, (state) => {
        state.loading = true
      })
      .addCase(putSimulacaoDadosOriginacaoThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(getCashBack.fulfilled, (state, action) => {
        state.error = false
        state.loading = false
        state.isCashBack = action.payload.cashbackDisponivelParaSimular
      })
      .addCase(getCashBack.pending, (state) => {
        state.loading = true
      })
      .addCase(getCashBack.rejected, (state) => {
        state.loading = false
        state.error = true
        state.isCashBack = false
      })

    builder
      .addCase(getAutorizacoes.fulfilled, (state) => {
        state.error = false
        state.loading = false
      })
      .addCase(getAutorizacoes.pending, (state) => {
        state.loading = true
      })
      .addCase(getAutorizacoes.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

function isSimulacaoAutorizada(
  object: ResponseSimulacao | ResponseSimulacaoAutorizadaPost,
): object is ResponseSimulacaoAutorizadaPost {
  if (object === null) {
    return false
  }
  return 'uuidProposta' in object
}

export const [simulacaoReducer, simulacaoActions] = [simulacaoSlice.reducer, simulacaoSlice.actions]

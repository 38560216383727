import styled from 'styled-components'

type ContainerProps = {
  paddingTop?: string
}
export const Container = styled.section<ContainerProps>`
  min-height: 100vh;
  width: 100vw;
  padding: 16px 24px 24px 24px;
  padding-top: ${({ paddingTop }) => paddingTop};
  display: flex;
  flex-flow: column nowrap;
  max-width: 600px;
  margin: 0 auto;
  background-color: var(--neutral-theme);
`

export const Content = styled.section`
  height: 100%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

export const Footer = styled.footer<{
  sticky?: {
    bottom?: string
  }
  isDarkMode?: boolean
}>`
  margin-top: 24px;
  flex-grow: 0;
  position: ${({ sticky }) => (sticky ? 'sticky' : '')};
  bottom: ${({ sticky }) => (sticky?.bottom ? sticky?.bottom : '1rem')};
  bottom: ${({ sticky }) => (sticky?.bottom ? sticky?.bottom : '1rem')};
  background-color: var(--neutral-theme);
  width: ${({ sticky }) => (sticky ? '100%' : '')};
  align-self: ${({ sticky }) => (sticky ? 'center' : '')};
`

import React from 'react'

import { Provider } from 'react-redux'
import ReactDOM from 'react-dom/client'

import { initTheme, Theme } from '@interco/inter-ui'
import store from '@/store'
import { NotificationProvider } from '@interco/inter-ui/components/Notification'

import Router from './routes'
import { InterUIFonts } from './assets/fonts'

const AppWithControlPanel = () => {
  React.useEffect(() => {
    initTheme(Theme.PF)
    InterUIFonts()
  }, [])

  return (
    <Provider store={store}>
      <NotificationProvider>
        <Router />
      </NotificationProvider>
    </Provider>
  )
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(<AppWithControlPanel />)
export default AppWithControlPanel

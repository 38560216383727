import InterRegularTTF from './Inter/Inter-Regular.ttf'
import InterMediumTTF from './Inter/Inter-Medium.ttf'
import InterSemiBoldTTF from './Inter/Inter-SemiBold.ttf'
import InterBoldTTF from './Inter/Inter-Bold.ttf'
import SoraRegularTTF from './Sora/Sora-Regular.ttf'
import SoraMediumTTF from './Sora/Sora-Medium.ttf'
import SoraSemiBoldTTF from './Sora/Sora-SemiBold.ttf'
import SoraBoldTTF from './Sora/Sora-Bold.ttf'

/**
 * Adiciona as fontes via javascript no head do HTML.
 *
 * @summary Se faz necessário incluir as fontes dessa forma, pois ao incluir através
 * do Styled Components (createGlobalStyle) o mesmo realiza um novo request para
 * carregamento das fontes ao ocorrer uma mudança no state da aplicação.
 */
export const InterUIFonts = () => {
  const style = document.createElement('style')

  style.textContent = FontFace

  document.head.appendChild(style)
}

const FontFace = `
  @font-face {
    font-family: 'Inter';
    src: url(${InterRegularTTF});
    src: url(${InterRegularTTF}) format('truetype');
    font-weight: normal;
  }

   @font-face {
    font-family: 'Inter';
    src: url(${InterMediumTTF});
    src: url(${InterMediumTTF}) format('truetype');
    font-weight: 200;
  }

   @font-face {
    font-family: 'Inter';
    src: url(${InterMediumTTF});
    src: url(${InterMediumTTF}) format('truetype');
    font-weight: 300;
  }

   @font-face {
    font-family: 'Inter';
    src: url(${InterMediumTTF});
    src: url(${InterMediumTTF}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterMediumTTF});
    src: url(${InterMediumTTF}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterSemiBoldTTF});
    src: url(${InterSemiBoldTTF}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Inter';
    src: url(${InterBoldTTF});
    src: url(${InterBoldTTF}) format('truetype');
    font-weight: 700;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraRegularTTF});
    src: url(${SoraRegularTTF}) format('truetype');
    font-weight: normal;
  }

   @font-face {
    font-family: 'Sora';
    src: url(${SoraMediumTTF});
    src: url(${SoraMediumTTF}) format('truetype');
    font-weight: 200;
  }

   @font-face {
    font-family: 'Sora';
    src: url(${SoraMediumTTF});
    src: url(${SoraMediumTTF}) format('truetype');
    font-weight: 300;
  }

   @font-face {
    font-family: 'Sora';
    src: url(${SoraMediumTTF});
    src: url(${SoraMediumTTF}) format('truetype');
    font-weight: 400;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraMediumTTF});
    src: url(${SoraMediumTTF}) format('truetype');
    font-weight: 500;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraSemiBoldTTF});
    src: url(${SoraSemiBoldTTF}) format('truetype');
    font-weight: 600;
  }

  @font-face {
    font-family: 'Sora';
    src: url(${SoraBoldTTF});
    src: url(${SoraBoldTTF}) format('truetype');
    font-weight: 700;
  }
`

import { ErrorType } from '@/pages/Error/CodeErros'

const validarErro = (error: string): ErrorType | undefined => {
  if (error === 'NAO_AUTORIZADO' || error === 'SEM_AUTORIZACAO_PARA_CONSULTAR_FGTS')
    return 'NAO_AUTORIZADO'
  if (error === 'SALDO_INSUFICIENTE') return 'SALDO_INSUFICIENTE'
  if (error === 'LIMITE_ANTECIPACAO_EXCEDIDO') return 'LIMITE_ANTECIPACAO_EXCEDIDO'
  if (error === 'NAO_ENCONTRADO') return 'NAO_ENCONTRADO'
  if (error === 'FORA_DO_HORARIO') return 'FORA_DO_HORARIO'
  if (error === 'CREDITO_INDISPONIVEL') return 'CREDITO_INDISPONIVEL'
  if (error === 'SERVICO_INDISPONIVEL') return 'SERVICO_INDISPONIVEL'
  if (error === 'PROCESSO_ANDAMENTO') return 'PROCESSO_ANDAMENTO'
  if (error === 'SIMULACAO_ASSINCRONA') return 'SIMULACAO_ASSINCRONA'
  if (error === 'MANUTENCAO_CADASTRAL_NO_FGTS') return 'MANUTENCAO_CADASTRAL_NO_FGTS'
  if (error === 'SERVICO_TEMPORARIAMENTE_INDISPONIVEL')
    return 'SERVICO_TEMPORARIAMENTE_INDISPONIVEL'

  return undefined
}

export default validarErro

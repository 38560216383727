import PayloadPostSimulacao from '@/models/api/PayloadPostSimulacao'
import ResponseSimulacaoAutorizada from '@/models/api/ResponseSimulacaoAutorizada'
import ResponseSimulacao from '@/models/api/ResponseSimulacao'
import ResponseSimulacaoAutorizadaPost from '@/models/api/ResponseSimulacaoAutorizada'
import { CashBackResponse } from '@/models/api/CashbackResponse'
import { ResponseAutorizacoesCEF } from '@/models/ResponseAutorizacoesCEF'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class SimulacaoService {
  static postSimulacao = (
    data: PayloadPostSimulacao,
    conta?: string,
  ): Promise<TResponseType<ResponseSimulacao | ResponseSimulacaoAutorizadaPost>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
      },
      pathname: process.env.REACT_APP_SIMULACAO_FGTS,
      method: HttpMethod.POST,
      data,
      host: process.env.REACT_APP_HOST,
    })

  static getSimulacao = (
    uuid: string,
    cpf: string | undefined,
    conta: string | undefined,
  ): Promise<TResponseType<ResponseSimulacao | ResponseSimulacaoAutorizada>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
        'x-inter-cpf': cpf || process.env.REACT_APP_CPF || '',
        uuid,
      },
      pathname: process.env.REACT_APP_SIMULACAO_DADOS_ORIGINACAO_FGTS,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })

  static putSimulacaoDadosOriginacao = (
    idAntecipacaoFgts: string,
    qtPeriodos: number,
    conta: string | undefined,
    autorizaConsultaCaixa?: boolean,
    saldoFgts?: number,
  ): Promise<TResponseType<ResponseSimulacao>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
      },
      pathname: process.env.REACT_APP_SIMULACAO_DADOS_ORIGINACAO_FGTS,
      method: HttpMethod.PUT,
      host: process.env.REACT_APP_HOST,
      data: {
        idAntecipacaoFgts,
        qtPeriodos,
        autorizaConsultaCaixa,
        saldoFgts,
      },
    })

  static getCashBack = (produto: string): Promise<TResponseType<CashBackResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        produto,
      },
      pathname: process.env.REACT_APP_CASH_BACK_FGTS,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })

  static getAutorizacoesCEF = (cpf: string): Promise<TResponseType<ResponseAutorizacoesCEF>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        cpf,
      },
      pathname: process.env.REACT_APP_AUTORIZACOES_CPF_CEF,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })
}

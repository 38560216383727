import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import BaseService from '@/services/BaseService'
import DebitoAutomaticoService from '@/services/DebitoAutomaticoService'

import { RootState } from '..'

export interface DebitoAutomaticoResponse {
  id: number
  cpf: string
  ativo: boolean
}

export interface DebitoAutomaticoState {
  loading: boolean
  error: boolean
  data?: DebitoAutomaticoResponse
  checked: boolean
  show: boolean
}

const initialState: DebitoAutomaticoState = {
  loading: false,
  checked: false,
  error: false,
  show: false,
}

export const getDebitoAutomatico = createAsyncThunk(
  'debitoAutomatico/getDebitoAutomatico',
  async (_, { rejectWithValue }) => {
    try {
      const user = await BaseService.getUserInfo()

      const { data } = await DebitoAutomaticoService.getDebitoAutomatico(user?.cpf)

      return data
    } catch (e) {
      return rejectWithValue(e)
    }
  },
)

export const debitoAutomaticoThunk = createAsyncThunk(
  'debitoAutomatico/postDebitoAutomatico',
  async (
    payload: {
      ativo: boolean
      onSucess: () => void
      error: () => void
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const user = await BaseService.getUserInfo()
      const rootStore = getState() as RootState

      const response =
        rootStore.debitoAutomatico?.data !== undefined
          ? await DebitoAutomaticoService.putDebitoAutomatico(payload.ativo, user?.cpf)
          : await DebitoAutomaticoService.postDebitoAutomatico(payload.ativo, user?.cpf)

      payload.onSucess()

      return response.data
    } catch (e) {
      payload.error()
      return rejectWithValue(e)
    }
  },
)

const debitoAutomaticoSlice = createSlice({
  name: 'debitoAutomatico',
  initialState,
  reducers: {
    setCheckedDebito: (state, action: PayloadAction<boolean>) => {
      state.checked = action.payload
    },
    reset: (state) => {
      state.checked = initialState.checked
      state.data = initialState.data
      state.error = initialState.error
      state.loading = initialState.loading
    },
    setDebitoAumatico: (state, { payload }: PayloadAction<DebitoAutomaticoResponse>) => {
      state.data = payload
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDebitoAutomatico.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = false
        state.show = true
      })
      .addCase(getDebitoAutomatico.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(getDebitoAutomatico.rejected, (state) => {
        state.loading = false
        state.error = true
      })

    builder
      .addCase(debitoAutomaticoThunk.fulfilled, (state, action) => {
        state.loading = false
        state.data = action.payload
        state.error = false
      })
      .addCase(debitoAutomaticoThunk.pending, (state) => {
        state.loading = true
        state.error = false
      })
      .addCase(debitoAutomaticoThunk.rejected, (state) => {
        state.loading = false
        state.error = true
      })
  },
})

export const [debitoAutomaticoSliceReducer, debitoAutomaticoSliceActions] = [
  debitoAutomaticoSlice.reducer,
  debitoAutomaticoSlice.actions,
]

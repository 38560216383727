import { IWbISafeResponse } from '@interco/inter-webview-bridge'
import { CcbResponse } from '@/models/api/CCBResponse'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class ContratacaoService {
  static postAutorizacaoIsafe = (
    isafe: IWbISafeResponse | undefined,
    conta: string | undefined,
  ): Promise<TResponseType<void>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
      },
      pathname: process.env.REACT_APP_POST_AUTORIZACOES_FGTS,
      method: HttpMethod.POST,
      host: process.env.REACT_APP_HOST,
      data: {
        tipoAutenticacao: isafe?.authenticationType || '',
        token: isafe?.authenticationToken || '',
        valor: isafe?.authenticationValue || '',
      },
    })

  static postContratar = (
    id: string,
    dataHora: string,
    qtPeriodos: number,
    seguroSolicitado: boolean,
  ): Promise<
    TResponseType<{
      uuidProposta: string
    }>
  > =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      pathname: process.env.REACT_APP_POST_CONTRATAR_FGTS,
      method: HttpMethod.POST,
      host: process.env.REACT_APP_HOST,
      data: {
        id,
        dataHora,
        qtPeriodos,
        seguroSolicitado,
      },
    })

  static posEnviarContrato = (data: {
    numeroProposta: string
    idTipoDocumento: string
    enviaSalesForce: boolean
  }): Promise<TResponseType<void>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      pathname: process.env.REACT_APP_ENVIO_CONTRATO,
      method: HttpMethod.POST,
      host: process.env.REACT_APP_HOST,
      data,
    })

  static getDadosCCB = (
    uuidSimulacao: string,
    qtPeriodos: number,
    conta?: string,
  ): Promise<TResponseType<CcbResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        uuid: uuidSimulacao,
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
        qtPeriodos: qtPeriodos.toString(),
      },
      pathname: process.env.REACT_APP_SIMULACAO_FGTS,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })

  static getRisk = (conta?: string): Promise<TResponseType<void>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        'x-inter-conta': conta || process.env.REACT_APP_CONTA || '',
      },
      pathname: process.env.REACT_APP_ELEGIBILIDADE_FGTS,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })
}

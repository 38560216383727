import React from 'react'

import { Container, Content, Footer } from './style'

type Props = {
  children: React.ReactNode
  footer?: React.ReactNode
  paddingTop?: string
  sticky?: {
    bottom?: string
  }
}

const Page = ({ children, footer, paddingTop, sticky }: Props) => (
  <Container paddingTop={paddingTop}>
    <Content>{children}</Content>
    {footer && <Footer sticky={sticky}>{footer}</Footer>}
  </Container>
)

export default Page

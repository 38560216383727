import { DebitoAutomaticoResponse } from '@/store/debitoAutomaticoSlice'

import BaseService from '../BaseService'
import { HttpMethod, TResponseType } from '../BaseService/types'

export default class DebitoAutomaticoService {
  static getDebitoAutomatico = (cpf?: string): Promise<TResponseType<DebitoAutomaticoResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        cpf: cpf || process.env.REACT_APP_CPF || '',
      },
      pathname: `${process.env.REACT_APP_DEBITO_AUTOMATICO_FGTS}`,
      method: HttpMethod.GET,
      host: process.env.REACT_APP_HOST,
    })

  static postDebitoAutomatico = (
    ativo: boolean,
    cpf?: string,
  ): Promise<TResponseType<DebitoAutomaticoResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
      },
      pathname: `${process.env.REACT_APP_DEBITO_AUTOMATICO_FGTS}`,
      method: HttpMethod.POST,
      host: process.env.REACT_APP_HOST,
      data: {
        cpf: cpf || process.env.REACT_APP_CPF || '',
        ativo,
      },
    })

  static putDebitoAutomatico = (
    ativo: boolean,
    cpf?: string,
  ): Promise<TResponseType<DebitoAutomaticoResponse>> =>
    BaseService.doRequest({
      headers: {
        'Content-Type': 'application/json',
        cpf: cpf || process.env.REACT_APP_CPF || '',
      },
      pathname: `${process.env.REACT_APP_DEBITO_AUTOMATICO_FGTS}`,
      method: HttpMethod.PUT,
      host: process.env.REACT_APP_HOST,
      data: {
        ativo,
      },
    })
}
